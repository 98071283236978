.sticky-footer-parent {
  height: auto;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 27rem;
  background: transparent;
  margin-bottom: 3rem;
  /* box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 20px 20px 0px 0px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.sticky-footer {
  /* height: 35px; */
  max-width: 46.7vw;
  width: 100%;
  position: fixed;
  bottom: 0;
  /* margin-bottom: 10px; */
  padding: 15px;
  background: #ffffff;
  /* box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1); */
  /* border-radius: 0 0 20px 20px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.type-msg {
  width: 35.375vw;
  height: 40px;
  border-radius: 10px;
  background: #f5f5f5;
  border: none;
  outline: none;
  padding-left: 3rem;
  padding-right: 4.5rem;
}

.type-msg:focus{
  background-color: #f5f5f5;
}

.send-msg-btn {
  color: #fde723;
  width: 40px;
  height: 40px;
  background: #fde723;
  border-radius: 10px 4px 18px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}

.input-box {
  position: relative;
  width: auto;
}

.emoji-icon {
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 1rem;
  padding-top: 0.7rem;
  width: 20%;
}

.camera-icon {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 4rem;
  padding-top: 0.7rem;
}

.camera-icon {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 3rem;
  padding-top: 0.7rem;
  
}

.plus-icon {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 1rem;
  padding-top: 0.76rem;
  width: 20%;
}

.plus-icon:hover,
.camera-icon:hover,
.emoji-icon:hover,
.send-msg-btn:hover {
  cursor: pointer;
}

.plus-menu-tooltip {
  mix-blend-mode: normal;
  width: 348px;
  height: 82px;
}

@media (max-width: 767px) {
  .send-msg-btn {
    margin-right: 1rem !important;
  }

  .type-msg {
    width: 215px;
  }
}

@media (max-width: 380px) {
  .type-msg {
    max-width: 130px;
  }
}
