/* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: white;
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: white;
}

.bg {
  background-color: lightgray;
}

input:checked ~ .bg {
  background-color: #22c55e;
}

label {
  font-size: medium;
}

.ant-btn-primary {
  background-color: #E67E22 !important;
}

.tags-wraaper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 2px solid lightgray;
  border-radius: 2px;
  /* background: #f0f0f0; */
  width: 100%;
  min-height: 42px;
  margin-top: 10px;
}
.tags-wraaper:hover {
  border-bottom: 2px solid lightgray;
}
.tags-wraaper .tags-container {
  display: flex;
  align-items: center;
  width: 92%;
  height: auto;
  flex-wrap: wrap;
}
.tags-wraaper .remove-tag-btn {
  color: black;
  padding-right: 5px;
}
.tags-wraaper .tag {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 15px;
  padding: 5px;
  margin: 5px;
  color: gray;
}
.tags-wraaper .tag span {
  margin-right: 5px;
  margin-bottom: 2px;
  margin-left: 2px;
}
.tags-wraaper .tag button {
  background: none;
  border: none;
  cursor: pointer;
}
.tags-wraaper input {
  padding: 5px;
  border: none;
  background: transparent;
}
.tags-wraaper input:focus, .tags-wraaper select:focus, .tags-wraaper textarea:focus, .tags-wraaper button:focus {
  outline: none;
}
.tags-wraaper .add-btn {
  font-weight: 500;
  cursor: pointer;
}

.fix-search span{
  white-space: normal;
  overflow: visible;
  word-wrap: break-word;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;

}



/* //////////////////////////////////////////////////////////// */

.orange{
  color: #ffa654;
}

.orangeBg{
  background-color: #ffa654;
}

.heading{
  font-size: 46px;
  align-self: center;
}

.paddingBot{
  padding-bottom: 3%;
}

.grey{
  color: #919191;
}

.parentCont{
  display: flex;
}

.slick-container{
  width: 35%;
}

.slickCar{
  margin-bottom: 5%;
  background-color: transparent;
 padding-bottom: 20px !important;
  font-size: medium;
}
.hideunhide{
  .slick-prev{
    display: none;
  }
}

.slick-dots{
  background-color: transparent;
  padding-bottom: 20px !important;
}

.slick-dots li.slick-active button:before {
  
  color: #e67e22 !important;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
  position: relative;
}
/* .form1 input {
  border-bottom: none !important;
} */

.form1{
  width: 100%;
  align-self: center;
}

.cstm{
  margin-top: -3%;
}

.caro cstm{
  margin-top: 0;
}

.mbtm{
  margin-bottom: 15px !important;
}

.marbot{
  margin-bottom: 15px;
  margin-right: 10px;
}

.spcl button{
  float: inline-end !important;
  margin-top: 12%;
}

.swalbtn{
  margin-right: 10px;
  
  padding: 10px 10px 10px 20px !important;
  font-size: 20px !important;
  background-color: #e67e22 !important;
  border: none !important;
  font-weight: 600 !important;
  color: white !important;
  border-radius: 5px !important;
  height: fit-content;
  margin-top: 5%;

}
.swalbtnCancel{
  margin-right: 10px;
  
  padding: 10px 10px 10px 10px !important;
  font-size: 20px !important;
  background-color: gray !important;
  border: none !important;
  font-weight: 600 !important;
  color: white !important;
  border-radius: 5px !important;
  height: fit-content;
  margin-top: 5%;

}
.btn{
  
  padding: 8px 40px !important;
  font-size: 20px !important;
  background-color: #e67e22 !important;
  border: none !important;
  font-weight: 600 !important;
  color: white !important;
  border-radius: 5px !important;
  height: fit-content;
  margin-top: 5%;
}
.form-btn{
  
  /* padding: 8px 40px !important; */
  font-size: 16px !important;
  background-color: #e67e22 !important;
  border: none !important;
  font-weight: 600 !important;
  color: white !important;
  border-radius: 5px !important;
  height: fit-content;
  margin-top: 5%;
}
.report-btn{
  
  /* padding: 8px 40px !important; */
  font-size: 16px !important;
  background-color: #e67e22 !important;
  border: none !important;
  font-weight: 600 !important;
  color: white !important;
  border-radius: 5px !important;
  height: fit-content;
}


.carousel-inner1{
  overflow: hidden;
}



 .fc .fc-timegrid-slot {
  height: 8rem !important;
  
  
} 

.fc-timegrid-event{
  height: max-content !important;
}

.fc-timegrid-col-events {
  display: flex !important;
  flex-direction: column !important;
}
.css-1aquho2-MuiTabs-indicator{
  background-color: #E67E22;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #E67E22;
}

.fc-day-today {
  background-color: #e67d2215 !important;
}

.ant-pagination-item-active a {
  color: #E67E22 !important;
  border-color: #E67E22 !important;
}

.ant-pagination-item-active {
  border-color: #E67E22 !important;
}

.muiButton-root.css-sghohy-MuiButtonBase-root-MuiButton-root{
  background-color: #E67E22 !important;
}



.MuiButton-root{
  background-color: #E67E22 !important;
}

.white-bg-btn{
  background-color: #FFEADC !important;
}

.pure-white-bg-btn{
  background-color: white !important;
}

.icon-tabler-edit{
  stroke: #e67e22 !important;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #E67E22 !important;
}

.MuiCircularProgress-colorPrimary {
  color: #E67E22 !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
  background-color: #E67E22 !important;
  border-color: #E67E22 !important;
  outline: none !important;
  box-shadow: none !important;
}

.fc-header-toolbar > .fc-toolbar-chunk:nth-child(2) {
  min-width: 5%;
}

.inputWrapper .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  width: 100%;
  background-color: white;
  border-color: white;
}

.inputWrapper .css-yu75kd-MuiGrid-root{
  width: 100%;
}

.inputWrapper .css-5sfu98-MuiGrid-root{
  width: 100%;
}

.inputWrapper .react-datepicker-wrapper{
  width: 100%;
}

.ant-table-thead .ant-table-cell{
  background-color: #FFEADC !important;
}

.MuiPaginationItem-icon, .ant-pagination-item-link{
  color: #E67E22 !important;
}

.MuiRadio-colorPrimary {
  color: #E67E22 !important;
}

.ant-modal-title, .ant-modal-close-icon{
  color: #E67E22 !important;
}

.ant-modal-title{
  font-size: 25px !important;
}

.ant-btn-default:not(:disabled):hover {
  color: #E67E22 !important;
  border-color: #E67E22 !important;
}

/* .MuiButton-textPrimary{
  color: white !important;
} */

.css-u72sq4-MuiTableCell-root.MuiTableCell-head {
  background-color: #E67E22 !important;
  
}

.react-datepicker-popper {
  z-index: 10 !important;
}

.dateWrap .react-datepicker-wrapper {
  width: 100%;
}

.modalImage{
  max-width: 265px !important;
  max-height: 216px !important;
  border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.hideDropdown{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
}

.css-1omp2b7.Mui-selected{
  background-color: #E67E22 !important;
}

.changeGoalModal .mantine-Modal-title {
  color: #E67E22;
  font-size: x-large;
}

.athleteLogin .fc-header-toolbar > .fc-toolbar-chunk:nth-child(2) {
  min-width: 20%;
}

 .css-1iq2dqk-MuiPaper-root, .css-pbqcab-MuiPaper-root {
  box-shadow: none !important;
}
.css-pbqcab-MuiPaper-root{
  border-right: 1px solid lightgray;
}

.antTableManage .ant-table-sticky-scroll{
  display: none;
}

.flow-flex-input .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input  .flow-flex-input .css-1x5jdmq {
  padding: 10px !important;
}


.fc .fc-toolbar.fc-header-toolbar{
  margin-left: 20px;
}

/* Add this CSS to your stylesheet or in a styled component */
.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #E67E22 white; /* Optional, for custom scrollbar color */
}

/* For WebKit browsers */
.thin-scrollbar::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
}

.thin-scrollbar::-webkit-scrollbar-track {
  background: white; /* Optional, for custom scrollbar track color */
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: #E67E22; /* Optional, for custom scrollbar thumb color */
  border-radius: 10px; /* Optional, for rounded scrollbar thumb */
  border: 3px solid white; /* Adjust the border as needed */
}

.overflowXNone{
  overflow-x: hidden;
}

.slick-slide div {
  outline: none; /* Ensure no outline appears on focus */
}


.custom-select__option--is-focused {
  background-color: #FFEADC !important;
  color: black !important;
}
.custom-select__option--is-selected {
  background-color: #E67E22 !important;
  color: white !important;
}

.DobInput input{
  padding: 7px 5px;
}

.DobInput .MuiInputBase-root, .locationInput .MuiInputBase-root ,.locationInput .MuiInputLabel-root{
  font-size: unset;
}


.locationInput .MuiInputLabel-root{
left: -8px;
}

.radioFlowBtn .MuiButtonBase-root{
  padding: 0 !important;
}

.css-1dr372x:hover{
  scrollbar-color: auto;
}

.css-1dr372x:last-child{
  overflow: visible !important;
}

.css-1dr372x:last-child:hover{
  overflow: visible !important;
  scrollbar-color: unset;
}

.css-1wzt60o-MuiList-root-MuiMultiSectionDigitalClockSection-root:hover{
  &::-webkit-scrollbar {
    background-color: initial;
    /* height: 3px; */
    width: 3px;
}
}

.css-1wzt60o-MuiList-root-MuiMultiSectionDigitalClockSection-root:hover{
  &::-webkit-scrollbar-thumb {
    background-color: #e67e22;
    border-radius: 3px;
}
}

.css-1dr372x{
  &::-webkit-scrollbar {
    background-color: initial;
    /* height: 3px; */
    width: 3px;
}
}

.css-1dr372x{
  &::-webkit-scrollbar-thumb {
    background-color: #e67e22;
    border-radius: 3px;
}
}

.CommentTextarea textarea{
  padding: 10px !important;
}

.ZonetableCss{
  background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    width: 100%;
}

.automationSelect .custom-select__value-container{
  font-size: 1rem !important;
}

.progressDetail .mantine-Modal-title{
  font-size: 2.25em;
}




/* //////////////////////// */
@media (max-width: 1920px) {

  /* .work-calendar{
    height: 111vh !important;
  } 
  */
    
  }

 

    

  @media (max-width: 1600px) {

    /* .work-calendar{
      height: 114vh !important;
    }  */
      
    }



@media (min-width: 1450px) {

/* .work-calendar{
  height: 124vh !important;
} 
   */
   /* .blockWidth{
    width: 150px !important; 
  } */
   
}
@media (min-width: 1550px) {

  /* .work-calendar{
    height: 122vh !important;
  } 
     */
     /* .blockWidth{
      width: 160px !important; 
    } */
     
  }
   @media (min-width: 1650px) {

    
    /* .weeklySummary{
      margin-top: 76px !important;
    } */
    /* .blockWidth{
      width: 165px !important; 
    } */
      
    }
    @media (min-width: 1750px) {

    
      /* .weeklySummary{
        margin-top: 76px !important;
      } */
      /* .blockWidth{
        width: 175px !important; 
      } */
        
      }
      @media (min-width: 1050px) {

    
        /* .weeklySummary{
          margin-top: 76px !important;
        } */
        /* .blockWidth{
          width: 100% !important; 
        } */
          
        }
        @media (max-width: 1200px) {

    
          .fontSmaller{
          font-size: 14px;
        }
            
          }
          @media (max-width: 1152px) {

    
            .athleteTab .weeklySummary{
              margin-top: 172px !important;
            }  
            }

          
        
@media (max-width: 1050px) {

  /* .headingCont{ */
    /* text-align: center; */
  /* } */
  /* .blockWidth{
    width: 125px !important; 
  } */
 
  .parentCont{
    flex-direction: column;
  }

  .form1{
    order: 2;
    margin-top: 5%;
    /* margin-left: 8%; */
  }

  .cstm{
    margin-top: 10px;
    margin-bottom: 20px;

  }

  

  .slick-container{
    width: 70%;
    padding-top: 2%;
    margin: 0 auto;

  }

  .slick-container.spclSlick{
    width: 50%;
  }
  .athleteTab .weeklySummary{
    margin-top: 272px !important;
  }
  .weeklySummary{
    margin-top: 172px !important;
  }

  .fontSmaller{
    font-size: 12px;
  }

  .widthSmaller{
    width: 78px !important;
  }

  .top36px{
    top: 46px !important;
  }

  /* .work-calendar{
    height: 113vh !important;
  }  */

}

@media (max-width: 950px) {

/* .work-calendar{
  height: 127vh !important;
} 
   */
   
   
}

@media (max-width: 800px) {

  
.weeklySummary{
  margin-top: 222px !important;
}
.profileImage{
  width: 8rem;
  height: 8rem;
  margin-left: 20px;
}

}

@media screen and (min-width: 900px) and (max-width: 1024px) {
  .maxWidth50{
    max-width: 370px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .maxWidth50{
    max-width: 220px !important;
  }
}




@media (min-width: 800px) {

  .css-18sywi9 {
    
    margin-left: 0 !important;
}




}
 @media (max-width: 500px) {
/*
  .heading{
    font-size: 32px;
  }
  .css-18sywi9 {
    margin-left: 8px;
}

.form1{
  margin-top: 10%;
}

.work-calendar{
  height: 116vh !important;
} 
 */ 
 .flow-flex-input .css-1x5jdmq {
  padding: 7px !important;
}



 .inAthlete .fc .fc-scrollgrid-liquid,.inAthlete .fc .fc-scrollgrid-section-liquid>td,.inAthlete .fc .fc-scroller-harness-liquid,.inAthlete .fc .fc-scroller-liquid-absolute {
  height: auto !important;
 }

 .coachBook .fc .fc-scrollgrid-liquid,.coachBook .fc .fc-scrollgrid-section-liquid>td,.coachBook .fc .fc-scroller-harness-liquid,.coachBook .fc .fc-scroller-liquid-absolute {
  height: auto !important;
 }

 .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
  display: none;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1em !important;
}

 .fc .fc-toolbar{
  flex-direction: column !important;
  align-items: end !important;
  margin-top: 10px;
 }

 /* .fc-toolbar-title{
  font-size: small !important;
 } */

 .fontSmaller{
  font-size: smaller !important;
 }
.goalModal .mantine-Modal-modal{
  scale: 0.8 ;
}

 .form1{
  margin-top: 10%;
}
.flow-section{
  /* background-image: none !important; */
  margin: 0 auto !important;
  width: 85% !important;
  /* background-image: url(./Images/Background.png) !important; */
}
 .btn {
  font-size: medium !important;
 }
 .zone-form-section{
  width: 100% !important;
}
 .marginZero{
  margin-left: 0 !important;
}
.zone-discription-group{
  margin: 10px !important;
}
.coachBook .fc .fc-toolbar{
  display: flex;
  flex-direction: column !important;
}
.zone-form-section-group{
  width: 100% !important;
}
.Flex{
  display: flex !important;
}
.flexCol{
  display: flex;
  flex-direction: column !important;
}
.add-challenges-modal{
  width: 100% !important;
}
.marginDec{
  margin-top: 20px !important;
}

.w-80p{
  width: 80vw !important;
}

.profileAutocomplete .css-1h51icj-MuiAutocomplete-root .MuiFilledInput-root .MuiFilledInput-input {
  padding: 18px 10px;
  background-color: #ffeadc !important;
}

.profilePage .css-10botns-MuiInputBase-input-MuiFilledInput-input{
  padding: 18px 10px;
  
} 

.profileAutocomplete .css-1869usk-MuiFormControl-root{
  width: 100%;
}

.columns > :last-child {
  display: none;
}

.alignRight{
  text-align: right !important;
}

.ht65vh{
  height: 75vh !important;
}

.borderTop{
  border-left: none !important;
  border-top: 1px solid rgb(230, 126, 34) !important;
}
.flexRev{
  display: flex;
  flex-direction: column-reverse !important;
}
.justifyEnd{
  align-self: flex-end;
}
.widthChange{
  width: 90% !important;
}
.rowGap{
  row-gap: 10px !important;
}
.relativePos{
  position: relative !important;
}

.direRtl{
  direction: rtl;
}

.sticky-footer{
  max-width: unset !important;
}

.sticky-next-btn{
  left: unset !important;
  right: 0 !important;
}
/* .input-box{
  width: 100% !important;
} */

.type-msg {
  max-width: unset !important;
  /* width: 150% !important; */
}

.widthFull{
  width: 100% !important;
}
.width100{
  width: 100% !important;
}
.add-grp-name-input{
  width: 14rem !important;
}
.unsetMaxWidth{
  max-width: unset !important;
}
.height100{
  height: 100vh !important;
}
.overflowUnset{
  overflow: unset !important;
}
.font16{
  font-size: 16px !important;
}
.font14{
  font-size: 14px !important;
}
.font12{
  font-size: 12px !important;
}
.mr20{
  margin-right: 20px !important;
}
.mt50p{
  margin-top: 50% !important;
}
.mt60p{
  margin-top: 60% !important;
}
.pt20{
  padding-top: 20px;
}
.mt80p{
  margin-top: 80% !important;
}
.mt70p{
  margin-top: 70% !important;
}
#wfull{
  width: 100% !important;
}

.mt130{
  margin-top: 130%;
}
.mt30p{
  margin-top: 115px;
}
.minHeight900{
  min-height: 850px;
}

.mb40p{
  margin-bottom: 40%;
}
.mt200{
  margin-top: 200%;
}
.pr10{
  padding-right: 10px !important;
}
.ml20{
  margin-left: 20px !important;
}
.mr50{
  margin-right: 50px !important;
}
.ml0{
  margin-left: 0 !important;
}
.width150{
  min-width: 150px !important;
}
.mr0{
  margin-right: 0 !important;
}
.ml55{
  margin-left: 55px;
}
.mr100{
  margin-right: 100px !important;
}
.pb20{
  padding-bottom: 20px !important;
}
.p0{
  padding: 0 !important;
}
.m0{
  margin: 0 !important;
}
.p10{
  padding: 10px !important;
}
.heightAuto{
  height: auto !important;
}

.width220{
  width: 220px !important;
}
.mt0{
  margin-top: 0 !important;
}
.font25{
  font-size: 25px !important;
}
.textStart{
  text-align: start !important;
}
.mt30{
  margin-top: 30px !important;
}
.mt20{
  margin-top: 20px !important;
}
.mt15{
  margin-top: 15px !important;
}
.mt10{
  margin-top: 10px !important;
}
.halfWidth{
  width: 50% !important;
}
.flexRow{
  display: flex !important;
}
.itemsCenter{
  align-items: center !important;
}
.headingCont{
  line-height: 1.2 !important;
  width: 95%;
}
.headingCont > h4{
  font-size: 23px !important;
}
.ant-table-thead > tr > th {
  white-space: nowrap;
  width: auto;
}
.wordBreakHead{
  white-space: normal !important;
  /* word-wrap: break-word !important; */
}
.smaller {
  font-size: 10px !important;
  
}
.heading{
  font-size: 32px;
}

.fc .fc-daygrid-day .fc-daygrid-day-frame {
  padding: 0px !important;
  min-height: auto;
}

.css-zx3mwh-MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
  background-color: white !important;
}

.fc-daygrid-day-events{
  display: flex;
  flex-wrap: wrap;
}

.fc .fc-daygrid-day-events .fc-daygrid-event-harness {
  background-color: transparent !important;
}

} 
.athleteTab .fc .fc-daygrid-body{
  height: unset !important;
  overflow: scroll;
}

.fc .fc-daygrid-body{
  height: 72vh !important;
  overflow: scroll;
}

.fc .fc-scrollgrid-section table{
  width: 100% !important;
}

.MuiAutocomplete-paper{
  scrollbar-color: #E67E22 transparent !important;
}

.groupStepOne{
  scrollbar-color: #eee8e8 transparent !important;
  scrollbar-width: thin;
}


@media (max-width: 1240px) {

  .work-calendar{
    height: 100vh !important;
  } 

  .workSidebar{
    height: 100vh !important;
  }

  .blockWidth{
    width: 12vw !important; 
  }
    
  }

  .athleteTab .fc .fc-daygrid-body{
    height: unset !important;
    overflow: scroll;
  }
  .checkScrollBar{
    padding: 0px 4px 2px 4px;
    overflow-x: auto;
    max-width: 140px;
    scroll-behavior: smooth;
    scrollbar-color: red;
    /* overflow: scroll; */
    scrollbar-color: auto;
    &::-webkit-scrollbar {
      height: 3px;
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #E67E22;
      border-radius: 3px;
    }
  
    
  }
.fc-direction-ltr .fc-toolbar > * > :not(:first-child){
  color: gray !important;
}
.css-mvmxd9-MuiTypography-root-MuiPickersToolbarText-root{
  background-color: white !important;
}
.css-ihhycx-MuiTimePickerToolbar-amPmSelection .MuiTimePickerToolbar-ampmLabel{
  background-color: white !important;

}
.css-hlj6pa-MuiDialogActions-root>:not(:first-of-type){
  color: white;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  color: white !important;
}

.assement-scroll{
  overflow-y: auto;
  max-height: 60vh;
  scroll-behavior: smooth;
  scrollbar-color: red;
  /* overflow: scroll; */
  scrollbar-color: auto;
  &::-webkit-scrollbar {
    height: 3px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E67E22;
    border-radius: 3px;
  }

  
}
.onboarding-flow-description{
  overflow-y: auto;
  max-height: 42vh;
  scroll-behavior: smooth;
  scrollbar-color: red;
  /* overflow: scroll; */
  scrollbar-color: auto;
  &::-webkit-scrollbar {
    height: 3px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E67E22;
    border-radius: 3px;
  }

  
}
.css-11lq3yg-MuiGrid-root{
  /* height: 80vh !important; */
    /* overflow: scroll !important; */
}
.css-92xg7j{
  height: 85vh !important;
  overflow: scroll !important;
}
.jodit-toolbar {
  display: none !important;
}

.jodit-status-bar {
  display: none !important;
}
.jodit-container jodit jodit_theme_default{
  display: none;
}
.rdw-editor-main{
max-height: 200px !important;
overflow-y: scroll !important;
padding: 10px;
scrollbar-color: auto;
  &::-webkit-scrollbar {
    height: 3px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E67E22;
    border-radius: 3px;
  }
}
.draftJsLink {
  color: blue !important;
  text-decoration: underline !important;
}
.rdw-link-decorator-wrapper{
  color: blue !important;
  text-decoration: underline !important;
}
.rdw-editor-wrapper .public-DraftStyleDefault-ul,
.rdw-editor-wrapper .public-DraftStyleDefault-ol {
  margin-left: 1.5em; /* Adjust this value to align bullets */
  padding-left: 1.5em; /* Adjust this value to align bullets */
}

.rdw-editor-wrapper .public-DraftStyleDefault-ol {
  list-style-type: decimal; /* Ensure ordered lists use decimal numbering */
}

.rdw-editor-wrapper .public-DraftStyleDefault-ul {
  list-style-type: disc; /* Ensure unordered lists use disc bullets */
}
  
  .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root{
    padding: 17px !important;
  }
  .css-4jnixx-MuiStack-root>:not(style):not(style){
    width: 100% !important;
  }
  .css-160unip > :not(style):not(style){
    width: 100% !important;
  }

  @media (max-width: 500px) {
    .textAlignUnset{
      text-align: unset !important;
    }
    .pt8{
      padding-top: 5px !important;
    }
    .flexCol{
      flex-direction: column;
    }
    .gap10{
      gap: 10px;
    }
    .flexColRev{
      flex-direction: column-reverse;
    }
    .mr10{
      margin-right: 10px !important;
    }
    .ratingParent .ratingChild{
      display: flex;
      flex-direction: column;
    }
    .height100{
      min-height: 100vh !important;
      max-height: 100vh !important;
    }
    .badgeWidth100{
      width: 100% !important;
    }
    .mb0{
      margin-bottom: 0 !important;
    }
    .custom-table .ant-table-container{
      min-width: 1000px !important;  
  }
  .ChatPaperHt{
    min-height: 83vh;
    max-height: 83vh;
  }
  .inputW95{
    width: 95%;
  }
  .stepperM10{
    margin-left: -10px;
  }
  .mt50{
    margin-top: 50px !important;
  }
  .top36px{
    top: 26px !important;
  }
  .justifyEnd{
    justify-content: flex-end;
  }
  .justifyCenter{
    justify-content: center;
  }
  .mt5{
    margin-top: 5px !important;
  }
  .textCenter{
    text-align: center;
  }
  .floatLeft{
    float: inline-end;
  }
  .minUnset{
  min-height: unset !important;
  }
  }
  .css-1l0dkjo-MuiFormLabel-root.Mui-focused{

    color: rgba(0, 0, 0, 0.6) !important;
  }
  .css-114o271.Mui-focused{
    color: rgba(0, 0, 0, 0.6) !important;
  }
  /* .css-1bn53lx{
    width: 92% !important;
  }
  .css-1nvf7g0{
    margin-right: 10px !important;
  } */
   .meal_time_picker{
    width: 100% !important;
    .css-1nvf7g0{
      margin-right: 10px !important;
    }
   }
   .meal_time_picker_1{

     .css-4jnixx-MuiStack-root{
      overflow: hidden !important;
     }
   }