.score-item {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.container {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 20px auto;
    max-width: 1000px;
}
.card-contener{
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 20px auto;
    max-width: 1000px;
}

.segment {
    margin: 20px 0;
    border: 2px solid #ccc;
    border-radius: 5px;
    padding: 10px;
}
.segment:nth-child(odd){
    background-color: #f7f7f7;
    border: 2px solid #ddd;
}
.segment-name {
    font-weight: bold;
    font-size: 18px;
}
.bar-container {
    width: 100%;
    background-color: #f2f2f2;
}

.bar {
    height: 20px;
    background-color: #4CAF50;
    transition: width 0.5s ease-in-out;
}
.percentage {
    font-weight: bold;
    padding-left: 10px;
}