.container {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
  
  .title {
    font-size: 30px;
    font-weight: 500;
  }
  
  .form-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
    justify-content: center;
  }
  
  .activity-inputs {
    width: 500px;
  }
  .activity-input {
    width: 70%;
  }
  
  .activity-input input {
    padding: 15px !important;
    /* border: solid 1px gray; */
  }

 .description-Input textarea {
    padding: 15px !important;
    height: auto !important;
  }
  
  .activity-autocomplete {
    width: 40%;
  }
  
  .activity-inputs #demo-simple-select {
    padding: 10px 10px !important;
  }
  .lable {
    font-size: 20px;
    font-weight: bold;
    width: 250px;
    min-width: 250px;
    text-align: right;
  }
  
  .MuiInputBase-root {
    padding: 0% !important;
  }
  
  .MuiFormLabel-root {
    margin-top: -7px;
  }
  
  .css-1wuilmg-MuiAutocomplete-root {
    width: 180px !important;
  }
  
  .activity-input .MuiInputLabel-root {
    margin: 0;
  }
  
  
  @media only screen and (max-width: 500px) {
    .container {
      padding: 5%;
      width: 90%;
      height: 90vh;
    }
    .form-body {
      justify-content: center;
      width: 100%;
    }
    .form-group {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 20px;
      justify-content: center;
      flex-direction: column;
    }
  
    .activity-inputs {
      width: 100%;
    }
  
    .activity-input {
      width: 90%;
    }
  
    .lable {
      font-size: 20px;
      font-weight: bold;
      width: 100%;
      /* min-width: 150px; */
      text-align: left;
    }
  
    .css-1wuilmg-MuiAutocomplete-root {
      width: 100% !important;
    }
  
    .activity-autocomplete {
      border: solid 1px gray;
      width: 60% !important;
    }
  }
  