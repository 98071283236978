.camera-container {
    max-width: 27rem;
    margin: 0 auto;
    height: 100vh;
    display: grid;
    place-items: center;
}

.open-camera-button {
    background-color: #2196f3;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    display: block;
    margin: 0 auto;
    margin-top: 50vh;
    transform: translateY(-50%);
    cursor: pointer;
}

.video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-preview {
    width: 100%;
    height: 100%;
}

.capture-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    color: #000000;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 10px;
    box-shadow: 0 0px 18px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.preview-container {
    text-align: center;
}

.photo-preview {
    max-width: 100%;
}

.retake-button {
    background-color: #ffffff;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
    color: #000000;
    border: none;
    border-radius: 25px;
    padding: 10px 25px;
    font-weight: bold;
    margin-top: 20px;
    margin-right: 10px;
    cursor: pointer;
    width: 10rem;
}

.send-button {
    background-color: #E67E22;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    border: none;
    width: 10rem;
    border-radius: 25px;
    padding: 10px 25px;
    margin-top: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.back-arrow {
    position: fixed;
    top: 20px;
    left: 20px;
    font-size: 24px;
    cursor: pointer;
    z-index: 100;
}

.back-arrow span {
    display: inline-block;
    transform: rotate(180deg);
    z-index: 100;
}