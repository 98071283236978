.walkjog-form-container {
  width: 100%;
  height: 100%;
}

.walkjog-form-main {
  padding: 20px;
  height: 85vh;
  overflow: scroll;
}

.walkjog-title-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px lightgray;
  padding-bottom: 15px;
}

.walkjog-title-section .title {
  font-size: 1.5rem;
}

.button-section {
  display: flex;
  gap: 30px;
}

.walkjog-form-section {
  width: 100%;
  padding-top: 20px;
}

.walkjog-form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.walkjog-form-group label {
  color: gray;
  font-weight: 500;
  font-size: 15px;
}

.walkjog-form-group input {
  padding: 6px;
  border: solid 1px gray;
  border-radius: 5px;
}

.walkjog-form-group #demo-simple-select {
  padding: 4px !important;
  border: solid 1px lightgray;
  border-radius: 5px;
}

.walkjog-second-row {
  padding-top: 10px;
  display: flex;
  gap: 20px;
}

.walkjog-acticity-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.walkjog-zone-section {
  background: lightgray;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-right: 30px;
}

.walkjog-zone-section .title {
  color: black;
  font-size: 12px;
  font-weight: 400;
  padding: 10px 10px 10px 10px;
}

.walkjog-zone-group {
  display: flex;
  width: 100%;
  padding: 10px;
  gap: 20px;
}

.walkjog-zone-group .metric {
  width: 40%;
}

.walkjog-zone-group .zone {
  width: 60%;
}

.walkjog-zone-group label {
  color: black;
}

.walkjog-third-row {
  display: flex;
  width: 100%;
  padding-top: 20px;
}

.walkjog-third-row #demo-simple-select {
  border: none !important;
}

.tag-input-section {
  width: 100%;
  background: lightgray;
  padding: 5px;
}

.tag-input-section input {
  background: lightgray !important;
}

.walkjog-tag-input {
  width: 100%;
  padding-top: 20px;
}

.walkjog-blocks-section {
  width: 100%;
  padding-top: 20px;
}

.walkjog-blocks-section .title {
  font-size: 15px;
  color: gray;
  font-weight: 500;
  padding-bottom: 10px;
}

.walkjog-blocks-main {
  background: lightgray;
  height: auto;
  width: 100%;
  padding: 5px;
}

.blocks_card {
  display: flex;
  background: white;
  height: auto;
  width: fit-content;
  padding: 5px;
  margin-bottom: 3px;
}

.walkjog-blocks-main-blocks {
  background: lightgray;
  height: auto;
  width: 100%;
  padding: 5px;
}

.rdw-editor-main {
  border: solid 1px gray;
  border-radius: 5px;
  min-height: 200px !important;
  overflow: hidden;
}

.walkjog-discription-section {
  padding-top: 20px;
}

.walkjog-discription-section .title {
  font-size: 15px;
  color: gray;
  font-weight: 500;
  padding-bottom: 10px;
}

.walkjog-footer-section {
  display: flex;
  justify-content: end;
  margin-top: 20px;
  width: 100%;
  padding-top: 20px;
  border-top: solid lightgray 1px;
}

@media only screen and (max-width: 700px) {
  .walkjog-title-section .title {
    font-size: 1rem;
  }
  .button-section {
    display: flex;
    gap: 10px;
  }
  .walkjog-zone-section {
    padding: 0px;
  }
  .walkjog-zone-group {
    flex-direction: column;
  }

  .walkjog-zone-group .metric {
    width: 90%;
  }

  .walkjog-zone-group .zone {
    width: 90%;
  }
}
.jodit-container:not(.jodit_inline) .jodit-wysiwyg{
  padding: 20px 20px 0px 20px  !important;
}
.jodit-container:not(.jodit_inline) .jodit-wysiwyg img{
  margin: 20px 20px 0px 52px !important;
}
ul {
  list-style: inside;
}
ol {
  list-style: inside;
  list-style-type: number;
}
.custom-table {
  border-collapse: collapse; /* Collapse borders */
}

.custom-table th {
  background-color: #FFEADC !important; /* Change header background color */
  font-weight: 700 !important;
  border: 1px solid gray !important;
}

.custom-table td {
  border: 1px solid gray !important; 
  border-top: none;
  /* border-bottom-left-radius:8px !important; */
  /* border-bottom-right-radius:8px !important; */
  /* Add border to cells */
}
.scroll-container {
  width: 100%;
  height: 100%; /* Adjust as needed */
  overflow: scroll !important; /* Enable scrolling */
}
.library-scrollbar {
  width: 100%;
  overflow: scroll; 
  scrollbar-width: thin;
}

.library-scrollbar::-webkit-scrollbar {
  display: none;
}