.running-step-input{
    width: 100%;
    padding: 5px;
    border: solid 1px gray;
    border-radius: 5px;
  }
  .running-step-input2{
    width: 50%;
    padding: 5px;
    border: solid 1px gray;
    border-radius: 5px;
  }
  .couponCode .css-b62m3t-container{
    width: 52% ;
  }
  .running-step-input1{
    width: 100%;
    
    /* padding: 5px; */
  }
  
  .flow-flex-input{
    width: 100%;
    display: flex;
    gap: 20px;
  }
  
  .flow-running-form{
    height: 100%;
  }
  .promo{
    width: 97%;
  }
  
  @media only screen and (max-width: 768px) {
    .flow-flex-input{
      width: 90%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    
    .flow-running-form{
      max-height: 400px;
      /* overflow-y: scroll; */
      width: 80%;
      margin-top: 10px;
    }
    .promo{
      margin-left: 3%;
      width: 90%;
    }
  }
  
  
  .flow-section{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center ;
  }
  .flow-section-1{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center ;
  }
  
  
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #1e40af; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1e40af; 
    border-radius: 10px;
  }

/* CSS */
.disabled-input {
    background-color: #e6dddd; /* Change this to your desired background color */
    color: #030303; /* Optionally, you can change the text color for better readability */
    /* Add any other styling you want for disabled input fields */
  }
  
  /* Apply the 'disabled-input' class to your disabled input elements */
  input:disabled {
    /* Apply the styles to disabled input elements */
    @extend .disabled-input;
  }