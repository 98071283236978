.celandar-main {
  width: 100%;
  /* padding: 2%; */
  /* border: solid 1px lightgray; */
}

.overlay {
  opacity: 0.5;
}

.demo-app {
  display: flex;
  width: 100%;
}

.demo-app-main {
  width: 74%;
}

.left-sidebar {
  width: 24%;
}

.fc-MyMonthView-button{
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: center !important;
}

.left-sidebar-add-button {
  width: 90%;
  padding: 5%;
  border-bottom: solid 1px lightgray;
}

.add-button-inner {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 2%;
  border-radius: 10px;
  background: rgb(151, 139, 223);
  color: white;
  cursor: pointer;
}

.filter-section {
  width: 90%;
  padding-left: 10%;
  margin-top: -10%;
  border-top: solid 1px lightgray;
}

.filter-title {
  text-align: left;
  font-size: 20px;
  color: grey;
}

.filter-title p {
  margin: 15px 0px 5px 0px;
}

.border {
  border-bottom: solid 1px lightgray;
  border-right: solid 1px lightgray;
}

.running {
  background: rgb(248, 189, 189);
}

.lable-running {
  background: red;
}

.running .fc-event-title {
  color: red;
}

.business {
  background: rgb(233, 189, 244);
}

.lable-business {
  background: purple;
}
.business .fc-event-title {
  color: rgb(198, 57, 236);
}

.swimming {
  background: rgb(192, 223, 246);
}

.lable-swimming {
  background: blue;
}

.swimming .fc-event-title {
  color: rgb(75, 70, 167);
}

.family {
  background: rgb(240, 240, 189);
}

.lable-family {
  background: yellow;
}

.family .fc-event-title {
  color: rgb(240, 177, 41);
}

.holiday {
  background: rgb(178, 240, 178);
}

.lable-holiday {
  background: green;
}

.holiday .fc-event-title {
  color: rgb(12, 154, 12);
}



.fc-header-toolbar {
  padding: 20px;
  margin-bottom: 0px !important;
}

.fc-header-toolbar > :first-child > div {
  display: flex;
  align-items: center;
}

.fc-col-header-cell-cushion {
  color: gray;
  padding: 10px 10px !important;
}

.fc-prev-button {
  /* background: none !important;
  color: gray !important; */
  border: none !important;
}

.fc-next-button {
  /* background: none !important;
  color: gray !important; */
  border: none !important;
}

.fc-toolbar-chunk .fc-toolbar-title {
  /* color: gray !important; */
  font-size: 20px ;
  width: max-content !important;
}

.fc-popover {
  margin-top: 5%;
}

.fc-popover-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-container {
  width: 100%;
}

.form-fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin: 15px 0px;
}

.sidebar-input {
  width: 97%;
  border-radius: 5px;
  border: 1px lightgray solid;
  height: 25px;
}

#demo-simple-select {
  padding: 3px 0px;
}

.lable-option-container {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.lable-option-text {
  margin: 0px;
  padding-left: 5px;
}

.lable-span {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.MuiList-root li {
  padding: 10px 0px;
}

.fc-event {
  border: solid 1px lightgray;
}

.fc-event-main {
  padding: 3px 3px;
}

/* /////////////////////////////////////////// */



.fc-toolbar-chunk button{
  /* color: grey ; */
  background-color: #fcfaff !important;
  /* border: none !important; */
  border: 1px solid #ECF0FD !important;
  
}

.fc-toolbar-chunk button:active,
.fc-toolbar-chunk button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}



.fc-next-button, .fc-prev-button{
  color: #e67e22 !important;
  
}

/* .fc-timegrid-axis-frame::before {
  content: "All day";
  margin-top: 10px;
} */



.fc-view-harness{
  background-color: #fcfaff !important;
}

.fc-toolbar-title{
  border-radius: 3px;
  padding: 5px 10px;
  background-color: #fcfaff !important;
  color: black !important;
  border: 1px solid #ECF0FD !important;
}

.fc-customButton1-button, .fc-customButton2-button,.fc-customButton3-button,.fc-customButton4-button,.fc-customButton5-button,.fc-customButton6-button, .fc-button-group button{
  color: gray !important;
  border: none !important;
}

/* .fc-MyMonthView-button{
  border-right: 1.5px solid gray !important;
  
}

.fc-MyDayView-button{
  border-left: 1px solid gray !important;
} */

.fc .fc-button-group > .fc-button:focus, .fc .fc-button-group > .fc-button:active, .fc .fc-button-group > .fc-button.fc-button-active {
  color: #e67e22 !important;
  
  font-weight: 800!important;
}

.fc-view-harness-active{
  background-color: white;
}

.fc .fc-button-group{
  border: 1px solid #ECF0FD !important;
  border-radius: 3px;
  padding: 0 !important;

}

.fc-button-group:first-child{
  border-right: 1px solid gray !important;
}

.fc .fc-daygrid-event-harness {
 
  background-color: #f3f5fb;
}

.fc-toolbar-title:before {
  content: url('../../../public/images/fi_calendar.png');
  margin-right: 8px; /* Adjust the margin as needed */
  margin-top: 5px;

}

/* Add a calendar icon before the MyMonthView button */
.fc-MyMonthView-button:before{
  content: url('../../../public/images/fi_calendar.png');
  margin-right: 8px; /* Adjust the margin as needed */
 vertical-align: middle;
 margin-top: 5px;

}

/* .fc-scroller.fc-time-grid-container {
  border-color: red !important;
} */

.fc-scrollgrid-liquid{
  background-color: #fcfaff;
}


:root {
  --fc-border-color: #ECF0FD;
  
}

.custom-header{
  vertical-align: middle;
}

.fc-button-active {
  color: #e67e22 !important;
}

.fc-header-toolbar .fc-toolbar-chunk:last-child {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 10px;
  margin-top: 10px;
}

.fc-customButton1-button:hover, 
.fc-customButton2-button:hover,
.fc-customButton3-button:hover,
.fc-customButton4-button:hover,
.fc-customButton5-button:hover,
.fc-customButton6-button:hover, 
.fc-button-group button:hover {
  color: #e67e22 !important;
  font-weight: 600 !important;
}



