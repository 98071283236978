.appBar {
    /* padding: 1rem !important; */
    padding: 0.6rem !important;
    justify-content: space-between !important;
    top: 0 !important;
    position: sticky !important;
    background-color: #F5F5F5;
    z-index: 10;
}

.headingOfPageContent {
    height: 27px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */


    color: #000000;
}

.chatTitle {
    color: var(--black-2, #111) !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    letter-spacing: 0.16px !important;
    padding-left: 0.5rem;
    align-self: center !important;
}

.readUnreadButton {
    margin-top: 3px;
    font-weight: 700 !important;
    font-size: 14px;
    color: gray;
    margin-left: -5px;
}

.nav-icon:hover {
    cursor: pointer;
}

.active-inactive {
    color: var(--cyan-2, #056B6B) !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    letter-spacing: 0.4px !important;
    display: 'inline-block' !important;
    padding-left: 4px;
}