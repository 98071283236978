.tranning-profile-main {
  padding: 20px;
}

.tranning-profile-section {
  width: 100%;
}

.tranning-profile-section .title {
  font-size: 24px;
  font-weight: 500;
}

.tranning-profile-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.profile-form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px 0px;
}

.form-radio-group {
  display: flex;
  gap: 10px;
}

.radio-group {
  display: flex;
  align-items: center;
}

.no-days-section {
  display: flex;
  gap: 20px;
  align-items: center;
}

.no-days-section .day {
  height: 30px;
  width: 30px;
  border: solid 1px red;
  color: red;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-days-section .day:hover {
  background: red;
  color: white;
}

.profile-table-section {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

.profile-input-form-group{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.profile-input-form-group .date-input{
    padding: 10px;
}

/* ============================================= table- section =============================== */

.tranning-table-section {
  width: 99%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border: solid 1px lightgray;
  border-radius: 10px;
}

.active-cell {
  color: blue;
}

.table-header {
  width: 96%;
  background: red;
  color: white;
  font-weight: 500;
  display: flex;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.table-row {
  width: 96%;
  display: flex;
  padding: 10px;
}

.border-row {
  border-bottom: solid 1px lightgray;
}

.day-cell {
  width: 30%;
}

.workout-cell {
  width: 70%;
}

.profile-table-btn-section{
    width: 96%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}
