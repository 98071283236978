.sticky-acceptmsg-prompt {
    height: 165px;
    width: 24.5rem !important;
    position: fixed;
    bottom: 10%;
    padding: 20px;
    background: #FFEADC;
    /* box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1); */
    border-radius: 20px;
    text-align: center;
    margin-left: 20px;
}

.accept-msg-heading {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 150%;
}

.accept-msg-desc {
    font-style: normal;
    font-weight: 600;
    font-size: 12px !important;
    line-height: 18px;
    text-align: center !important;
    color: #575A6D;
    padding: 0.6rem 2rem 0rem 2rem;
}

/* .css-0{
    margin-top: -10px;
} */

.decline-btn {
    width: 45% !important;
    text-align: center;
    border: 2px solid #F5F5F5 !important;
    background-color: #F5F5F5 !important;
    margin-right: 0.5rem !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    letter-spacing: 0.05em;
    letter-spacing: 0.05em;
    text-transform: none !important;
    margin-top: 1rem !important;
    color: #000000 !important;
}

.accept-btn {
    width: 45% !important;
    text-align: center;
    border: 2px solid #E67E22 !important;
    background-color: #E67E22 !important;
    margin-left: 0.5rem !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    letter-spacing: 0.05em;
    letter-spacing: 0.05em;
    text-transform: none !important;
    margin-top: 1rem !important;
    color: white !important;
}