

.dropZone {
  flex: 0 0 auto;
  transition: 200ms all;
}

.dropZone:nth-of-type(2n) {
  display: none;
}

.dropZone.horizontalDrag {
  height: auto;
}

.dropZone:not(.horizontalDrag).isLast {
  flex: 1 1 auto;
  width: 150px;
  min-height: 50px;
  max-width: 100px;
  margin: 15px;
}

.dropZone.active,
.trashDropZone.active {
  background: #00a2ff;
  transition: 100ms all;
}

.column {
  border: 1px solid blue;
  flex: 1 1 100%;
  padding: 10px;
}

.columns {
  display: flex;
  padding: 0 0;
}

.calendar-header {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.calendar-day {
  min-height: 150px;
  max-height: auto;
  /* min-width: 143px;
  max-width: 143px; */
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.customCalenderTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customcalenderTdTh{
  text-align: left;
  padding: 8px;
  font-size: 11px;
}

.css-jfdv4h-MuiStack-root > :not(style) ~ :not(style) {
  margin-top: 0px !important;
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  display: flex !important;
  justify-content: left !important;
  color: black !important;
}
