.zones-form-container{
    padding: 20px;
}
.zones-form-main{
    width: 100%;
}

.table-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.zone-table-title{
    display: flex;
}

.zone-form-section{
    width: 100%;
    margin-top: 20px;
}
.zone-form-section-group{
    width: 30%;
    margin-top: 20px;
}
.zone-form-group{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 35%;
    :where(.css-dev-only-do-not-override-mxhywb).ant-picker .ant-picker-input >input{
        border: none !important;
    }
    :where(.css-dev-only-do-not-override-mxhywb).ant-picker .ant-picker-suffix{
        color:  transparent !important;
    }
}
.zone-discription-group{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0px;
    font-size: 14px;
}

.zone-form-group label{
    font-size: 18px;
    font-weight: 500;
}

.zone-form-group input{
    border: solid 1px lightgray !important;
    padding: 5px;
    border-radius: 5px;
}