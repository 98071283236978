.timeStamp {
    color: #E67E22;
    font-weight: 600;
    padding-right: 5px;
    font-size: 12px;
}

.badgeIcon {
    display: inline-block;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: #E67E22;
    color: white;
    text-align: center;
}

.chat-icon {
    /* margin: 5px; */
}

.userName {
    /* color: #111111;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: 0.7px !important; */

    color: var(--black-2, #111) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: 0.7px !important;
}

.acceptReqPrompt {
    color: #FDA623 !important;
    font-size: 10px !important;
    font-family: Open Sauce Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    letter-spacing: 0.08px !important;
}

.start-new-chat {
    color: var(--black-2, #111) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: 0.14px !important;
}

.chatPaper1 {
    scrollbar-width: thin !important;
    scrollbar-color: #eee8e8 white !important;
    
}

.chatPaper {
    scrollbar-width: thin;
    scrollbar-color: lightGray white;
    
}