.remove-nd-msg-btn {
    width: 8.5rem !important;
    border: 0.1px solid black !important;
    border-radius: 25px !important;
    font-size: 12px !important;
    text-transform: none !important;
}

.remove-btn-color {
    background-color: #FF2020 !important;
    color: #FFFFFF !important;
}

.msg-btn-color {
    background-color: #056B6B !important;
    color: #FFFFFF !important;
}