@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  letter-spacing: 0.2px;
  /* font-family: 'Open Sans', sans-serif; */
}
a {
  color: inherit;
  /* text-decoration: underline; */
}

.example::-webkit-scrollbar {
  display: none;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
  width: 1px;
  background: transparent;
  /* make scrollbar transparent */
}

body::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background: #E67E22;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
}

.fnsueh {
  font-family: 'Open Sans', sans-serif;
}

.iiKhtO>.wrapper {
  border-radius: 4px !important;
}

.iiKhtO>.wrapper:hover {
  box-shadow: none !important;
}

.fcUPmr {
  overflow-y: scroll !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mantine-1gg5jl {
  padding: 6px 12px;
}

/* Calendar start */
.demo-app {
  display: flex;
  width: 100%;
  min-height: 100%;
  font-size: 14px;
}

.demo-app-sidebar {
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.fc .fc-scrollgrid-section-liquid>td {
  height: 100vh !important;
}

.fc {
  /* the calendar root */
  max-width: 100%;
  margin: 0 auto;
}

.fc .fc-toolbar {
  /* flex-wrap: wrap; */
  gap: 0.6rem;
}

.fc .fc-button-group>.fc-button {
  padding: 0.5rem 1rem;
}

.fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
  /* margin: 0.5rem 1rem; */
  font-size: 12px !important;
}

.fc .fc-button:not(:disabled) {
  font-size: 12px !important;
}

.fc .fc-daygrid-day-bottom {
  padding: 0 !important;
}

.fc .fc-daygrid-day-frame {
  padding: 1rem;
  /* min-height: 170px !important;   */
}

/* .fc-daygrid-event {
  white-space: break-spaces !important;
} */
/* .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events { */
  /* min-height: 5rem !important; */
  /* height: 300px !important ; */
  /* overflow: scroll; */
/* } */

/* Calendar end */

.mantine-5v12bf {
  border-left: none;
  border-right: none;
  border-top: none;
  padding-left: 0;
  font-size: 18px;
  border-radius: 0;
}

.mantine-5v12bf:focus {
  outline: none;
  border: none;
}

.mantine-xizyzk {
  padding-left: 0;
}

.mantine-ukbbnm {
  padding: 0;
}


.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 0px 0px 10px !important;
}

body {
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: transparent transparent;
  /* Firefox */

  /* Hide scrollbar for IE, Edge, and Chrome */
  -ms-overflow-style: none;
  /* IE, Edge */
  overflow: -moz-scrollbars-none;
  /* Firefox */
  font-size: 10px;

}

/* Hide scrollbar for Webkit (Chrome, Safari, etc.) */
body::-webkit-scrollbar {
  width: 5px; 
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.fc .fc-daygrid-day .fc-daygrid-day-frame {
  padding: 0px !important;
  min-height: 332px ;
}

.custom-calendar {
  width: 100%;
  height: 200px;
  overflow: auto;
}

.example::-webkit-scrollbar {
  display: none;
}

/* Adjusting height and width of the calendar */
.custom-calendar .fc {
  height: 600px;
  /* Change the height as needed */
  width: 100%;
  /* Set the width to fill its container */
}

/* Changing font styles */
.custom-calendar .fc {
  font-family: Arial, sans-serif;
  /* Specify your desired font family */
  font-size: 12px !important;
  /* Set the font size */
}

.fc-header-toolbar {
  padding: 10px !important;
}

.fc .fc-button-primary {
  height: 40px !important;
  font-size: 17px !important;
  border: 1px solid #e67e22 !important;
}
.fc .fc-button-group > .fc-button:focus, .fc .fc-button-group > .fc-button:active, .fc .fc-button-group > .fc-button.fc-button-active{
  color: #a34f04 !important;
}
.fc .fc-view-harness {
  height: 80vh !important;
  /* overflow: scroll; */
}

.fc .fc-scrollgrid-section table {
  font-size: 15px !important;
}


/* Example styling for events */
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  border: none !important;
  margin: 2px !important;
}

.fc .fc-toolbar-title {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.fix-search {
  scrollbar-color: #E67E22 transparent  !important;
  .cugzTX>.wrapper {
    height: 300px !important;
    overflow: scroll !important;
  }

  .ffXrxx .ellipsis {
    width: 100% !important;
    /* overflow-x: scroll !important; */
    
  }

  ::-webkit-scrollbar {
    width: 4px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey !important;
    border-radius: 10px !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #E67E22 !important;
    border-radius: 10px !important;
  }
}

.jVORrT,.ewTnKk > svg  {
  fill: #E67E22 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  padding-left: 30px !important;
}

.fc .fc-daygrid-day-top {
  flex-direction: column !important;
  position: sticky !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-modal .ant-modal-header {
  border-bottom: 1px solid gray !important;
  padding-bottom: 19px !important;
}

.fc .fc-scroller-liquid-absolute {
  position: relative !important;
  height: 100vh !important;
  overflow: scroll;
}

.eventon-day {
  min-height: 316px;

  /* max-height: 172px; */
  /* overflow-y: scroll; */
}

.eventon-day2 {
  min-height: 362px;

  /* max-height: 206px;
  overflow-y: scroll; */
}

.eventon-day3{
  min-height: 460px !important;
}

.athleteTab .runningDurationMargin{
  margin-top: 0 !important;
}

.eventon-day1 {
  /* max-height: 178px;
  overflow-y: scroll; */
  min-height: 316px;
}
.fc .fc-timegrid-slot {
  height: 4rem !important;
  
  
}
.dropZone.active {
  background-color: rgb(244, 253, 255) !important;
}
.css-1aquho2-MuiTabs-indicator{
  background-color: orange !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #e67e22 !important;
}
.form-control:focus, .form-control:active {
  background: transparent !important;
}
input:focus {
  background-color: #fff;
  border-radius: 10px;
  outline: none !important;
}
.css-4jlksw-MuiTableCell-root.MuiTableCell-head{
  background-color: #e67e22 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root{
  text-transform: none !important;
}
.css-1pq08e7.MuiTableCell-head{
  background-color: rgb(230 126 34) !important;
}
.css-akiiyj.MuiTableCell-head{
  background-color: rgb(230 126 34) !important;
}
.eUeawN > .wrapper{
  height: 400px !important;
  overflow: scroll !important;
}
.fc .fc-daygrid-event-harness-abs{
  right: 0 !important;
  top: 0 !important;
}
.fc .fc-daygrid-event-harness{
  margin-top: 0px !important;
}