.select-buddies-text {
    padding-top: 1rem;
    padding-left: 1rem;
    color: #707070;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 15px;
    letter-spacing: 0.01em;
}

.search-buddy-input {
    width: 95%;
    height: 40px;
    background: #F5F5F5;
    border-radius: 10px;
    border: none;
    outline: none;
    padding-left: 1.5rem;
    color: #707070;
    font-size: 12px;
    margin-top: 0.7rem;
    padding-right: 4rem;
}

.search-message-input {
    width: 60%;
    height: 40px;
    background: #F5F5F5;
    border-radius: 10px;
    border: none;
    outline: none;
    padding-left: 1.5rem;
    color: #707070;
    font-size: 12px;
    margin-top: 0.7rem;
    padding-right: 4rem;
    margin: auto;
    margin-left: 5px;
}

input::placeholder {
    color: #707070;
}

.search-icon-parent {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 0;
    top: 0;
    background: #FDE723;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.3rem;
    margin-top: 1rem;
}

.cross-button {
    height: 18px;
    width: 18px;
    background-color: #707070;
    border-radius: 50%;
    border: 1.5px solid #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    margin-top: 2.2rem;
    margin-right: 1px;
    top: 0;
}

.profile-row:hover {
    cursor: pointer;
}

.create-grp-next-btn {
    width: 100px;
    height: 40px;
    background-color: #E67E22;
    position: fixed;
}

.sticky-next-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #E67E22;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    width: 110px;
    height: 48px;
    background: #E67E22;
    border-radius: 50px;
    margin-right: 1rem;
}

.next-mui-btn-selected {
    width: 100% !important;
    height: 100% !important;
    background: #E67E22 !important;
    border-radius: 50px !important;

    font-style: normal;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    letter-spacing: 0.01em !important;
    color: white !important;
    text-transform: none !important;
    transition: 0.3s ease;
}

.next-mui-btn {
    width: 100% !important;
    height: 100% !important;
    background: #B7B6B1 !important;
    border-radius: 50px !important;

    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    letter-spacing: 0.01em !important;
    color: #ffffff !important;
    text-transform: none !important;
    transition: 0.1s ease;
}

.add-grp-name-input {
    width: 20rem;
    height: 40px;
    padding-left: 1rem;
    margin-left: -7px;
    padding-right: 2.2rem;
    background: #FDFDFD;
    border-bottom: 1px solid #056B6B;
    border-radius: 10px 10px 0px 0px;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
}

.add-grp-name-input::placeholder {
    letter-spacing: 0.05em;
    line-height: 15px;
    font-weight: 300;
    font-size: 12px;
    color: #707070;
}

.group-name-length {
    position: absolute;
    right: 0;
    margin-right: 1.4rem !important;
    letter-spacing: 0.05em !important;
    line-height: 10px !important;
    font-weight: 600 !important;
    font-size: 8px !important;
    color: #707070 !important;
}

.create-grp-emoji {
    margin-right: 0.7rem;
}

@media (min-width: 481px) {

    .add-grp-name-input {
        width: 20rem;
    }

    .sticky-next-btn {
        margin-left: 22%;
    }
}

@media (min-width: 1281px) {

   

    .sticky-next-btn {
        margin-left: 25%;
    }
}

.selectects-avatar-name {
    color: var(--black-2, #111) !important;
    text-align: center !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    letter-spacing: 0.1px !important;
    padding-top: 5px;
}

.followers-text {
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: normal !important;
    letter-spacing: 0.6px !important;
    padding-left: 1.2rem;
    margin-top: 3px !important;
    display: flex;
    align-items: center;
}