.MuiMenu-list{
  padding: 10px !important;
}

.workout-form-container {
  width: 100%;
  height: 100%;
}

input{
  /* border-bottom: solid 1px gray !important; */
  border: none;
}

.workout-form-title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px !important;
}

.workout-form-main {
  padding: 20px;
}

.workout-form-body {
  border: 1px solid black;
  padding: 20px;
}

.workout-form-group {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin: 10px;
}

.workout-form-group input {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 2%;
}

.workout-form-group label {
  font-weight: 500;
}

.workout-form-group textarea {
  height: 100px;
}

.form-third-row {
  display: flex;
  gap: 30px;
  margin-top: 10px;
}

.third-row-leftside {
  border: solid 1px gray;
  padding: 2%;
  margin: 10px;
}

.third-row-leftside .main-title {
  font-size: 15px;
  color: gray;
  text-align: right;
  margin: 0;
}

.third-row-rightside {
  border: solid 1px gray;
  padding: 2%;
  margin: 10px;
}
.important-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.left-first-row {
  display: flex;
  width: 100%;
}

.planned-form {
  width: 50%;
  border-right: solid 1px black;
  border-bottom: solid 1px black;
}
.planned-form .title p {
  text-align: center;
  color: black;
}
.planned-form-group {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 20px;
}
.planned-form-group .two-inputs input {
  width: 100%;
  padding: 10px;
}
.planned-form-group input {
  width: 60%;
  padding: 10px;
}
.planned-form-group .two-inputs .select {
  width: 100%;
  /* border: 1px solid gray; */
}
.workout-form-container .MuiOutlinedInput-notchedOutline {
  border: none;
}

.actuals-form {
  width: 40%;
  border-bottom: 1px solid black;
  padding: 0% 5%;
}
.actuals-form .title p {
  text-align: center;
  color: black;
}
.actuals-form-group {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 20px;
}
.actuals-form-group .two-inputs input {
  width: 100%;
  padding: 5px;
}
.actuals-form-group input {
  padding: 10px;
}
.two-inputs {
  display: flex;
  width: 100%;
  gap: 20px;
}
.actuals-form-group .two-inputs .select {
  width: 100%;
  /* border: 1px solid gray; */
}
.small-device-label {
  display: none;
  font-weight: 500;
}
.full-device-label {
  font-weight: 500;
}
@media only screen and (max-width: 1300px) {
  .small-device-label {
    display: block;
  }
  .full-device-label {
    display: none;
  }
  .left-first-row {
    flex-direction: column;
  }
  .left-first-row .title {
    display: none;
  }
  .planned-form {
    width: 100%;
    border: none;
  }
  .actuals-form {
    width: 100%;
    border: none;
  }
  .actuals-form-group .two-inputs input {
    width: 50%;
    padding: 5px;
  }
  .planned-form-group {
    padding: 0% 0%;
  }
  .planned-form-group .two-inputs input {
    width: 50%;
    padding: 5px;
  }
  .actuals-form {
    padding: 0% 0%;
  }
}

@media only screen and (max-width: 800px) {
  .small-device-label {
    display: block;
  }
  .full-device-label {
    display: none;
  }
  .left-first-row {
    flex-direction: column;
  }
  .left-first-row .title {
    display: none;
  }
  .planned-form {
    width: 100%;
    border: none;
  }
  .actuals-form {
    width: 100%;
    border: none;
  }
  .actuals-form-group .two-inputs input {
    width: 50%;
    padding: 5px;
  }
  .planned-form-group {
    padding: 0% 0%;
  }
  .planned-form-group .two-inputs input {
    width: 50%;
    padding: 5px;
  }
  .actuals-form {
    padding: 0% 0%;
  }
}

@media only screen and (max-width: 500px) {
  .third-row-leftside p {
    text-align: center;
  }
  .planned-form-group {
    flex-direction: column;
    align-items: inherit;
    margin: 10px;
  }
  .planned-form-group input {
    width: 90% !important;
    padding: 10px;
  }
  .planned-form-group .two-inputs input {
    width: 50%;
    padding: 5px;
  }
  .planned-form-group .two-inputs .select {
    width: 50%;
    /* border: 1px solid gray; */
  }
  .actuals-form-group {
    flex-direction: column;
    align-items: inherit;
    margin: 10px;
  }
  .actuals-form-group input {
    width: 90% !important;
    padding: 10px;
  }
  .actuals-form-group .two-inputs input {
    width: 50%;
    padding: 5px;
  }
  .actuals-form-group .two-inputs .select {
    width: 50%;
    /* border: solid 1px gray; */
  }
  .important-section{
    flex-direction: column;
    height: 200px;
  }
  .rightside-comment-textarea{
    height: 100px !important;
  }
}

.left-second-row {
  display: flex;
  width: 100%;
  padding: 20px;
}
.zone-row {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 50%;
}
.zone-row label {
  font-weight: 500;
  color: black;
}

.zone-row .select {
  /* border: 1px solid gray; */
  width: 68% !important;
  margin-left: 7%;
}

.one-input .select {
  width: 100% !important;
}

@media only screen and (max-width: 800px) {
  .zone-row {
    width: 100% !important;
    align-items: center;
  }
  .zone-row label {
    width: 15%;
  }
  .zone-row .select {
    width: 75% !important;
    margin-left: 0;
  }
}

@media only screen and (max-width: 500px) {
  .left-second-row {
    padding: 10px;
  }
  .zone-row {
    width: 100% !important;
    align-items: flex-start;
    flex-direction: column;
  }

  .zone-row .select {
    width: 90% !important;
    margin-left: 0;
  }
}

.left-third-row {
  width: 100%;
  padding: 20px;
  padding-top: 0px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.label-main {
  width: 11%;
}

.planned-inputs {
  width: 40%;
}

.actuals-inputs {
  width: 40%;
  padding: 0% 10%;
  margin-left: -10px;
}

.third-row-form-group {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.third-row-two-inputs {
  display: flex;
  gap: 20px;
  width: 100%;
}

.third-row-two-inputs input {
  width: 100%;
}

.third-row-two-inputs .select {
  /* border: solid 1px gray; */
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .left-third-row {
    padding-top: 0px;
  }
  .third-row-form-group {
    flex-direction: column;
    width: 90%;
    gap: 20px;
  }
  .planned-inputs {
    display: flex;
    width: 100%;
  }
  .actuals-inputs {
    display: flex;
    width: 100%;
    padding: 0%;
    margin: 0%;
  }
}
@media only screen and (max-width: 500px) {
  .left-third-row {
    width: 100%;
    padding: 10px;
    gap: 0px;
  }
  .planned-inputs {
    flex-direction: column;
  }
  .actuals-inputs {
    flex-direction: column;
  }
}

.rating-section{
    width: 100%;
    padding: 20px;
    padding-top: 0px;
}
.rating-title{
    font-size: 15px;
    color: black;
    text-align: left;
    font-weight: 500;
}

.submit-section{
  padding: 0px 20px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-section{
  display: flex;
  align-items: center;
  gap: 10px;
}

.submit-section .submit{
  width: 50%;
}

.submit-section .submit button{
  width: 100% !important;
  background: rgb(255, 204, 122);
  border-radius: 20px;
}