.date-box {
  height: 1rem;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  border-radius: 10px;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #707070;
}

.date-box-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.7rem;
}

.ind-chat-date {
  /* font-family: 'Open Sauce Sans'; */
  font-style: normal;
  font-weight: bold !important;
  font-size: 10px !important;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.05em;
}

.creator-badge-text {
  /* font-family: 'Open Sauce Sans'; */
  font-style: normal;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.05em !important;
}

.text-msg-box {
  padding: 1rem;
  /* display: inline-block; */
  border-radius: 10px;
  padding-bottom: 1.1rem;
  padding-top: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  /* identical to box height, or 21px */
  letter-spacing: 0.05em !important;
  text-align: left;
  min-width: 100px;
  max-width: fit-content;
  /* max-width: 50%;
    min-width: 3rem; */
  line-break: anywhere;
  position: relative;
}

.text-msg-timing-parent {
  margin-top: 0;
  padding-bottom: 0.3rem;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 0.5rem;
  margin-bottom: -3.2px;
  text-align: right;
}

.text-msg-timing {
  font-size: 9px !important;
  font-weight: 300 !important;
}

.photo-msg-parent {
  width: auto;
  height: auto;
  background-color: #fff388;
  border-radius: 10px;
  margin: 0.5rem 1rem 0.5rem 1rem;
  padding-bottom: 2rem;
  text-align: center;
  padding: 5px;
  display: inline-block;
}

.photo-msg-img-box {
  border-radius: 10px 10px 0 0 !important;
}

.photo-msg-view-post-btn {
  height: 24px;
  background-color: #fdfdfd;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
}

.photo-img-icon {
  height: 27px;
  width: 27px;
  background-color: #00000026;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
}

.view-post-text {
  /* font-family: 'Open Sauce Sans'; */
  font-style: normal;
  font-weight: 500;
  font-size: 10px !important;
  line-height: 10px;
  letter-spacing: 0.01em;
  color: #056b6b;
}

/* .photo-img-texts-aligns {} */

.video-msg-box {
  width: 222px;
  height: 255px;
  top: 692px;
  padding: 5px;
  border-radius: 10px 10px 10px 10px;
  display: inline-block;
}

.video-box {
  height: 224px;
  border-radius: 10px 10px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1000;
}

.joinplan-msg-box {
  height: auto;
  width: 258px;
  min-height: 152px;
  border-radius: 10px 10px 10px 10px;
  padding: 5px;
  display: inline-block;
}

.joinplan-box {
  min-height: 110px;
  background-color: white;
  border-radius: 10px 10px 0 0;
}

.documentss-box {
  background-color: white;
  border-radius: 10px 10px 0 0;
}

.join-plan-btns {
  width: 50%;
  background-color: #fde723;
  text-align: center;
  font-size: 11px;
  padding: 5px;
  border-radius: 0px 0px 0px 10px;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
}

.view-post-btns {
  width: 50%;
  background-color: #e4eeee;
  text-align: center;
  font-size: 11px;
  padding: 5px;
  border-radius: 0px 0px 10px 0px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.isTravellingTotext {
  font-size: 9px !important;
  padding: 2px;
  padding-left: 5px;
}

.join-plan-desc {
  padding: 0.5rem;
  padding-left: 1rem;
  font-size: 10px !important;
  text-align: left !important;
}

.view-post-btns:hover,
.join-plan-btns:hover,
.view-post-text:hover {
  cursor: pointer;
}

.plus-bar-content {
  height: 69.87px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.27);
  border-radius: 10px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5rem;
  z-index: 1000;
  display: flex;
  padding: 5px 20px 5px 20px;
}

.plus-bar-icon-div {
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.7rem;
  padding-left: 0.7rem;
}

.plus-bar-icon-label {
  text-align: center !important;
  letter-spacing: 0.01em !important;
  color: #707070 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 8px !important;
  line-height: 150% !important;
  padding-top: 0.2rem;
}

.line-plus-icon-menu {
  height: 50%;
  border-left: 1px solid #707070;
  margin: auto;
}

.senderName {
  color: black;
  font-family: Open Sauce Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.5px;
  text-align: left;
}

/* meta card */

.meta_card {
  width: 200px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid lightgray;
  color: black;
  background-color: #f2f2f2;
  padding: 8px;
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.meta_img {
  width: 100%;
}

.meta_desc,
.meta_title,
.meta_sitename {
  margin: 0;
  padding: 0;
}

.meta_desc {
  font-size: 14px;
}

.meta_sitename {
  font-size: 12px;
}
