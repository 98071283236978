.workout-screen-section {
  width: 100%;
  height: 100%;
}

.workout-button-section{
    width: 100%;
    display: flex;
    padding-top: 1%;
    justify-content: space-evenly;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.Mui-disabled {
  background-color: #f0f0f0; /* Change this to the desired background color */
}
