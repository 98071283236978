.chat-press-unit-box {
    display: flex;
    padding: 0.72rem;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid #F5F5F5;
    border-radius: 10px 10px 0px 0px;
}

.chat-press-unit-heading {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    letter-spacing: 0.05em !important;
    color: #111111 !important;
}